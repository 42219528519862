<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          Edycja sprzętu </v-card-title
        ><GearForm isEdit :gear="gearDetails" @submit="submit"></GearForm>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import GearForm from "@/components/GearForm.vue";
import { useRoute, useRouter } from "vue-router";
import router from "@/router";
import {url} from "@/consts/consts";
export default defineComponent({
  name: "EditGear",
  components: { GearForm },
  setup() {
    const route: any = useRoute();
    const gearDetails = ref([]);
    const gearId: any = ref(null);

    onMounted(async () => {
      gearId.value = parseInt(route.params.id);
      try {
        const response = await fetch(
          `${url}/api/gear/${gearId.value}`
        );
        const data = await response.json();
        gearDetails.value = data[0];
      } catch (error: any) {
        console.error("Error loading gear status:", error.message);
      }
    });

    async function submit(value: any): Promise<void> {
      const response = await fetch(
        `${url}/api/gear/edit/${parseInt(route.params.id)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(value),
        }
      );
        if(response.status === 200){
            await router.push({name: 'gearList'});
        }
    }

    return { gearDetails, submit };
  },
});
</script>
