<template>
  <v-form>
    <v-text-field
      v-model="mutableNewRent.eventName"
      label="Nazwa eventu"
      variant="underlined"
      :readonly="readOnly()"
    ></v-text-field>
    <v-text-field
      v-model="mutableNewRent.location"
      label="Miejsce gdzie sprzęt będzie pracował"
      variant="underlined"
      :readonly="readOnly()"
    ></v-text-field>
    <v-row>
      <v-col cols="6"
        ><v-text-field
          v-model="mutableNewRent.startDate"
          type="date"
          label="Odbiór sprzętu"
          variant="underlined"
          @change="getManyItems"
          :readonly="readOnly()"
        ></v-text-field
      ></v-col>
      <v-col cols="6">
        <v-text-field
          v-model="mutableNewRent.endDate"
          type="date"
          label="Oddanie sprzętu"
          variant="underlined"
          @change="getManyItems"
          :readonly="readOnly()"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-autocomplete
      v-model="mutableNewRent.selectedItems"
      :items="[...items, ...multiItems]"
      item-value="id"
      :item-title="(item) => item.name + ' [' + item.itemId + ']'"
      label="Potrzebny sprzęt do wynajęcia"
      multiple
      variant="underlined"
      v-show="!readOnly()"
    ></v-autocomplete>
      <v-row v-if="addNew" v-show="mutableNewRent.status === 1">
          <v-col>
              <v-text-field v-model="multipleItems.quantity" label="Potrzebna ilość" type="number" variant="underlined">
              </v-text-field>
          </v-col>
          <v-col>
              <v-autocomplete
                      v-model="multipleItems.type"
                      :items="gearType"
                      item-value="id"
                      item-title="name"
                      label="Potrzebny typ sprzętu do wynajęcia"
                      variant="underlined"
                      v-show="!readOnly()"
              ></v-autocomplete>
          </v-col>
          <v-col>
              <v-btn @click="addMultipleItems">
                  Dodaj
              </v-btn>
          </v-col>
      </v-row>


    <v-row v-show="mutableNewRent.status < 4" v-if="true">
      <v-col>
        <v-data-table
          :headers="
            anyItemHaveLockState() && store.isAdmin() ? headersForAdmin : headersForUser
          "
          :items="gearItemsWithState()"
        >
          <template v-slot:item="{ item }">
            <tr>
              <!--              <td>{{ item.selectedItemId }}</td>-->
              <td>
                {{ item.gearName + " [" + item.itemId + "]" }}
              </td>
              <td v-if="anyItemHaveLockState() && store.isAdmin()">
                {{ item.message[0] }}
              </td>
              <td>
                {{
                  item.status === -1
                    ? "Dostępny"
                    : item.status === 2
                    ? "Zarezerwowany"
                    : "Oczekuje na zatwierdzenie"
                }}
              </td>
                <td>
                    {{ item.statusName }}
                </td>
              <td v-if="anyItemHaveLockState()">
                <v-btn
                  v-show="item.status && (store.isAdmin() || store.isOwner(mutableNewRent.userId))"
                  @click="removeItem(item.selectedItemId)"
                >
                  Usuń z wynajmu</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"
        ><v-text-field
          v-model="mutableNewRent.eventStartDate"
          type="date"
          label="Start eventu"
          variant="underlined"
          :readonly="readOnly()"
        ></v-text-field
      ></v-col>
      <v-col cols="6">
        <v-text-field
          v-model="mutableNewRent.eventEndDate"
          type="date"
          label="Koniec eventu"
          variant="underlined"
          :readonly="readOnly()"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-text-field
      v-model="mutableNewRent.invitedUsers"
      label="Zaproszone osoby (opcjonalnie)"
      variant="underlined"
      :readonly="readOnly()"
    ></v-text-field>
    <v-textarea
      v-model="mutableNewRent.comments"
      label="Szczegóły"
      variant="underlined"
      :readonly="readOnly() && mutableNewRent.status !== 2"
    ></v-textarea>
    <v-card-actions>
      <v-btn outlined color="primary" to="/magazyn">Anuluj </v-btn>
      <div class="flex-grow-1"></div>

      <v-btn @click="submitReject" color="primary" v-show="showButtons()"
        >Odrzuć
      </v-btn>

      <v-btn
        @click="isEdit = true"
        color="primary"
        v-show="showButtons() && !isEdit"
        >Edytuj
      </v-btn>

      <v-btn
        @click="submitEdited"
        color="primary"
        v-show="showButtons() && isEdit"
        >Zatwierdź zmienione
      </v-btn>

        <v-btn
                @click="submitEditedAsAdmin"
                color="primary"
                v-show="showButtons() && isEdit"
        >Zatwierdź zmienione
        </v-btn>

      <v-btn
        @click="submitAccept"
        color="primary"
        v-show="showButtons() && mutableNewRent.status !== 2 && !hideAccept"
        >Zatwierdź</v-btn
      >

      <v-btn
        @click="submitReturn"
        color="primary"
        v-show="mutableNewRent.status === 2 && (store.isAdmin() || store.isOwner(mutableNewRent.userId))"
        >Zwróć</v-btn
      >

      <v-btn @click="submitNew" color="primary" v-if="addNew"
        >Dodaj nowy wynajem</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  toRefs,
  watch,
} from "vue";
import { itemsList, itemStatus, url } from "@/consts/consts";
import { format } from "date-fns";
import { store } from "@/store/store";

interface EventDetails {
  id: null | number;
  status: null | number;
  userId:  string | number;
  eventName: string;
  location: string;
  startDate: null | string;
  endDate: null | string;
  eventStartDate: null | string;
  eventEndDate: null | string;
  selectedItems: number[];
    selectedMultiItems: number[];
  invitedUsers: string;
  comments: string;
  test123: [];
  selectedItemsForThatDate: any;
}
export default defineComponent({
  name: "rentForm",
    computed: {
        store() {
            return store
        }
    },
  props: {
    rent: {
      type: Object,
      default: () =>
        ({
          id: null,
          status: null,
          userId: '2',
          eventName: "",
          location: "",
          startDate: "2024-03-09",
          endDate: "2024-03-09",
          eventStartDate: "2024-03-09",
          eventEndDate: "2024-03-09",
          selectedItems: [],
            selectedMultiItems: [],
          invitedUsers: "",
          comments: "",
            test123: [],
          selectedItemsForThatDate: [],
        } as EventDetails),
    },
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const rent = toRef(props, "rent");
    const mutableNewRent = reactive<EventDetails>({
      id: null,
      status: 1,
      userId: store.userData.id,
      eventName: "",
      location: "",
      startDate: "",
      endDate: "",
      eventStartDate: "",
      eventEndDate: "",
      selectedItems: [],
        selectedMultiItems: [],
      invitedUsers: "",
      comments: "",
        test123: [],
      selectedItemsForThatDate: [],
    });

    const isEdit = ref(false);
    const hideAccept = ref(false);


    const items = ref([]);
    const multiItems = ref([]);
      const gearType = ref([]);
      const multipleItems = ref({
          quantity: 0,
          type: null,
      })

    const headersForAdmin = [
      { title: "Nazwa Sprzętu", key: "gearName" },
      { title: "Konflikt", key: "data" },
      { title: "Dostępność", key: "status" },
        { title: "Status sprzętu", key: "statusName" },
      { title: "Akcja", key: "status" },
    ];

    const headersForUser = [
      { title: "Nazwa Sprzętu", key: "gearName" },
        { title: "Dostępność", key: "status" },
        { title: "Status sprzętu", key: "statusName" },
      { title: "Akcja", key: "status" },
    ];

    onMounted(async () => {
      try {
        const response = await fetch(`${url}/api/gear`);
        let itemsArray = await response.json();

          items.value = itemsArray.filter((item: any) => {
              if(item.type === 0 && props.addNew) {
                  return item
            } else if(!props.addNew) {
                  return item
              }
          })
          console.log(items.value)
      } catch (error: any) {
        console.error("Error loading gear status:", error.message);
      }

      try {
          const response = await fetch(`${url}/api/gear-type`);
          const gearTypee = await response.json();
          gearType.value = gearTypee.filter((item: any) => item.id > 0)
      } catch (error: any) {
          console.error("Error loading gear status:", error.message);
      }

        multiItems.value = mutableNewRent.test123
    });

      async function getManyItems2() {
          console.log('getManyItems2', JSON.parse(JSON.stringify(mutableNewRent)))
          if(mutableNewRent.startDate && mutableNewRent.endDate) {
              console.log('if')
              try {
                  const response = await fetch(`${url}/api/many-gear?startDate=${mutableNewRent.startDate}&endDate=${mutableNewRent.endDate}`);

                  multiItems.value = await response.json();
                  console.log(multiItems.value)
              } catch (error: any) {
                  console.error("Error loading gear status:", error.message);
              }
          }
      }
    async function getManyItems() {
          console.log('getManyItems')
        mutableNewRent.selectedItems = []
        if(mutableNewRent.startDate && mutableNewRent.endDate) {
            try {
                const response = await fetch(`${url}/api/many-gear?startDate=${mutableNewRent.startDate}&endDate=${mutableNewRent.endDate}`);

                multiItems.value = await response.json();
                console.log(multiItems.value)
            } catch (error: any) {
                console.error("Error loading gear status:", error.message);
            }
        }
    }

    function gearItemsWithState(): any {
        let text: any = [];
        let b =  mutableNewRent.selectedItems.map((selectedItem) => {
            return (
                mutableNewRent.selectedItemsForThatDate
                    .map((lockedItem: any) => {
                        text.push(
                            `${
                                lockedItem.status === 2
                                    ? "Wynajęte na " +
                                    lockedItem.name +
                                    "' " +
                                    formattedDate(lockedItem.startDate) +
                                    "-" +
                                    formattedDate(lockedItem.endDate)
                                    : "Oczekuje na zawierdzenie na " + lockedItem.name
                            }`
                        );

                        return { ...lockedItem };
                    })
                    .map((lockedItem: any) => {
                        console.log('text', lockedItem)
                        return { ...lockedItem, message: text };
                    })[0] ??
                [...items.value, ...multiItems.value]
                    .filter((item: any) => {
                        return selectedItem * 1 === item.id;
                    })
                    .map((item: any) => {
                        return {
                            ...item,
                            status: -1,
                            gearName: item.name,
                            selectedItemId: item.id,
                            message: [],
                        };
                    })[0]
            );
        });
        console.log('gearItemsWithState', [...items.value, ...multiItems.value])
        return b
    }

    function anyItemHaveLockState() {
        return 0
      let b = gearItemsWithState().filter((item: any) => item.status < 3);

      return b.length;
    }

    function addMultipleItems() {
        const allItems = [...items.value, ...multiItems.value];
        const selectedItemFromType = mutableNewRent.selectedItems
        const allItemsToSelect = allItems.filter((item: any) => item.type === multipleItems.value.type).filter((item: any) => !selectedItemFromType.some((selectedItem: any) => selectedItem === item.id));

        if(allItemsToSelect.length < multipleItems.value.quantity){
            alert(`Nie udało się dodać potrzebnej ilości, dodano ${allItemsToSelect.length}`);
        }

        const array: number[] = []
        allItemsToSelect.forEach((item: any, index) => {
            if(index+1 <= multipleItems.value.quantity){
                console.log(item.id)
                array.push(item.id)
            }
        })

        mutableNewRent.selectedItems = [...mutableNewRent.selectedItems, ...array]
        multipleItems.value.quantity = 0;
        multipleItems.value.type = null
    }

    function formattedDate(originalDateString: string) {
      const originalDate = new Date(originalDateString);
      return format(originalDate, "dd.MM.yyyy");
    }
    function submitNew(): void {
      mutableNewRent.status = 1;
      emit("submit", mutableNewRent);
    }
    function submitAccept(): void {
      mutableNewRent.status = 2;
      emit("submit", mutableNewRent);
    }
    function submitEdited(): void {
      mutableNewRent.status = mutableNewRent.status === 0 ? 1 : 0;
      emit("submit", mutableNewRent);
    }
    function submitEditedAsAdmin(): void {
        // mutableNewRent.status = mutableNewRent.status === 0 ? 1 : 0;
        emit("submit", mutableNewRent);
    }

    function submitReturn(): void {
      mutableNewRent.status = 3;
      emit("submit", mutableNewRent);
    }
    function submitReject(): void {
      mutableNewRent.status = 4;
      emit("submit", mutableNewRent);
    }

    function readOnly(): boolean {
      return (!store.isAdmin() && !store.isOwner(mutableNewRent.userId)) || (!props.addNew && !isEdit.value);
    }

    function removeItem(id: any) {
      let a = mutableNewRent.selectedItems.filter((item) => item !== id * 1);
      hideAccept.value = true;
      isEdit.value = true;
      mutableNewRent.selectedItems = a;
    }
    function showButtons(): boolean {
    return (mutableNewRent.status === 0 || store.isAdmin()) && mutableNewRent.status !== 4 && !props.addNew && (store.isAdmin() || store.isOwner(mutableNewRent.userId));
    }

    watch(rent, (newGear) => {
      Object.assign(mutableNewRent, newGear);
    });

    return {
      mutableNewRent,
      items,
      submitNew,
      submitAccept,
      submitEdited,
        submitEditedAsAdmin,
      submitReject,
      submitReturn,
      isEdit,
      readOnly,
      showButtons,
      formattedDate,
      gearItemsWithState,
      headersForAdmin,
      removeItem,
      headersForUser,
      anyItemHaveLockState,
      hideAccept,
        getManyItems,
        multiItems,
        gearType,
        multipleItems,
        addMultipleItems
    };
  },
});
</script>
