<template>
  <v-row>
    <v-col>
      <!--        <img to="/" alt="Vue logo" src="./assets/logo.png" />-->
      <v-btn to="/magazyn"> home </v-btn>
        {{returnEmail()}}

        <v-btn to="/magazyn/login" @click="logout"> wyloguj </v-btn>
    </v-col>
  </v-row>
  <v-row class="app-body">
    <v-col>
      <router-view />
    </v-col>
  </v-row>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-body {
}
</style>
<script setup>
import { store } from './store/store.ts'
const returnEmail = () => {
    return store.userData?.email
};

const logout = () => {
    localStorage.removeItem("jwtToken");
    store.userData = {}
}
</script>
