import { reactive } from 'vue'

function isAdmin() {
    return store.userData?.type === 1
}

function isOwner(id: string | number) {
    console.log('isOwner', store.userData?.id, id
    )
    return store.userData?.id == id
}
export const store = reactive({
    userData: {
        id: "0",
        type: 0,
    },
    JWTauth: '',
    isAdmin,
    isOwner,
})