<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          Rezarwacje ({{showAllReservation ? 'wszystkie' : 'ostatnie 10'}})
        </v-card-title>

          <v-btn @click="getAllReservations"> Pobierz wszystkie rezerwacje</v-btn>

        <v-data-table :headers="headers" :items="rentList">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.eventName }}</td>
              <td>{{ item.location }}</td>
              <td>{{ formattedDate(item.startDate) }}</td>
              <td>{{ formattedDate(item.endDate) }}</td>
              <td>{{ formattedDate(item.createdAt, true) }}</td>
              <td>{{ item.userName }}</td>
              <td>{{ item.name }}</td>
              <td>
                <v-btn :to="`/magazyn/rentDetails/${item.id}`"> Zobacz</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { format } from "date-fns";
import { store } from "@/store/store";
import { url } from "@/consts/consts";
export default defineComponent({
  name: "rentList",
  setup() {
    const headers = [
      { title: "Nazwa eventu", key: "eventName" },
      { title: "Lokalizacja", key: "location" },
      { title: "Start wynajmu", key: "eventStartDate" },
      { title: "Koniec wynajmu", key: "eventEndDate" },
      { title: "Data zapytania", key: "createdAt" },
      { title: "Kto", key: "userId" },
      { title: "Status", key: "status" },
      { title: "Akcja", key: "action" },
    ];

    const rentList = ref([]);
    const showAllReservation = ref(false)

    function formattedDate(originalDateString: string, time = false) {
      const originalDate = new Date(originalDateString);
      return time
        ? format(originalDate, "dd.MM.yyyy HH:mm")
        : format(originalDate, "dd.MM.yyyy");
    }

    async function getAllReservations() {
        try {
            const response = await fetch(`${url}/api/reservation?userId=${store.userData.id}&userType=${store.userData.type}&all=true`);
            showAllReservation.value = true
            rentList.value = await response.json();
        } catch (error: any) {
            console.error("Error loading gear status:", error.message);
        }
    }

    onMounted(async () => {
      try {
        const response = await fetch(`${url}/api/reservation?userId=${store.userData.id}&userType=${store.userData.type}&all=false`);
        // const data = await response.json();
        rentList.value = await response.json();
      } catch (error: any) {
        console.error("Error loading gear status:", error.message);
      }
    });

    return { headers, rentList, formattedDate, getAllReservations, showAllReservation };
  },
});
</script>
