<template>
  <div class="home">ERROR</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorPage",
  setup() {
    return {};
  },
});
</script>
