import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
});

import vue3GoogleLogin from "vue3-google-login";

createApp(App)
  .use(router)
  .use(vuetify)
  .use(vue3GoogleLogin, {
    clientId:
      "136546612994-83v3lh48ope8setdem53b17ttqmuh33v.apps.googleusercontent.com",
  })
  .mount("#app");
