<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          Nowe zapytanie o wynajem
        </v-card-title>
        <RentForm :addNew="true" @submit="submit"></RentForm>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import RentForm from "@/components/RentForm.vue";
import router from "@/router";
import { url } from "@/consts/consts";
export default defineComponent({
  name: "newRent",
  components: { RentForm },
  setup() {
    async function submit(value: any): Promise<void> {
      const response = await fetch(
        `${url}/api/add-reservation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(value),
        }
      );
      if(response.status === 201){
          await router.push({name: 'rentList'});
      }
    }

    return { submit };
  },
});
</script>
