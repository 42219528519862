import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_RentForm = _resolveComponent("RentForm")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "subtitle-1 text-uppercase" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Szczegóły zapytania o wynajem na " + _toDisplayString(_ctx.rentDetails.eventName), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_RentForm, {
                rent: _ctx.rentDetails,
                activeEdit: false,
                onSubmit: _ctx.submit,
                onSubmitEdited: _ctx.submitEdited,
                onSubmitReject: _ctx.submitReject
              }, null, 8, ["rent", "onSubmit", "onSubmitEdited", "onSubmitReject"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}