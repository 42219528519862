import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "subtitle-1 text-uppercase" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Rezarwacje (" + _toDisplayString(_ctx.showAllReservation ? 'wszystkie' : 'ostatnie 10') + ") ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, { onClick: _ctx.getAllReservations }, {
                default: _withCtx(() => [
                  _createTextVNode(" Pobierz wszystkie rezerwacje")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_data_table, {
                headers: _ctx.headers,
                items: _ctx.rentList
              }, {
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(item.eventName), 1),
                    _createElementVNode("td", null, _toDisplayString(item.location), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formattedDate(item.startDate)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formattedDate(item.endDate)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.formattedDate(item.createdAt, true)), 1),
                    _createElementVNode("td", null, _toDisplayString(item.userName), 1),
                    _createElementVNode("td", null, _toDisplayString(item.name), 1),
                    _createElementVNode("td", null, [
                      _createVNode(_component_v_btn, {
                        to: `/magazyn/rentDetails/${item.id}`
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Zobacz")
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}