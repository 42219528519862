<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          gearList
            <v-btn to="/magazyn/addNewGear"> Dodaj nowy sprzęt </v-btn>
        </v-card-title>

        <v-data-table :headers="headers" :items="gearList">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.itemId }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.statusName }}</td>
                <td>{{ item.details }}</td>
              <td>
                <v-btn :to="`/magazyn/editGear/${item.id}`" v-if="store.userData?.type === 1">
                  editGear {{ item.key }}</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { itemsList, url } from "@/consts/consts";
import {store} from "@/store/store";
export default defineComponent({
  name: "gearList",
    computed: {
        store() {
            return store
        }
    },
  setup() {
    const headers = [
      { title: "ID", key: "itemId" },
      { title: "Nazwa", key: "name" },
      { title: "Status", key: "status" },
        { title: "Szczegóły", key: "details" },
      { title: "Akcja", key: "action" },
    ];

    const gearList = ref([]);

    onMounted(async () => {
      try {
        const response = await fetch(`${url}/api/gear`);
        // const data = await response.json();
        gearList.value = await response.json();
      } catch (error: any) {
        console.error("Error loading gear status:", error.message);
      }
    });

    return { headers, gearList };
  },
});
</script>
