import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "subtitle-1 text-uppercase" }, {
                default: _withCtx(() => [
                  _createTextVNode(" gearList "),
                  _createVNode(_component_v_btn, { to: "/magazyn/addNewGear" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Dodaj nowy sprzęt ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_data_table, {
                headers: _ctx.headers,
                items: _ctx.gearList
              }, {
                item: _withCtx(({ item }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(item.itemId), 1),
                    _createElementVNode("td", null, _toDisplayString(item.name), 1),
                    _createElementVNode("td", null, _toDisplayString(item.statusName), 1),
                    _createElementVNode("td", null, _toDisplayString(item.details), 1),
                    _createElementVNode("td", null, [
                      (_ctx.store.userData?.type === 1)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            to: `/magazyn/editGear/${item.id}`
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" editGear " + _toDisplayString(item.key), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["headers", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}