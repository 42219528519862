<template>
    <div class="home">Brak dostępu</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Error403",
    setup() {
        return {};
    },
});
</script>
