<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="subtitle-1 text-uppercase">
          Dodawanie nowego sprzętu
        </v-card-title>
        <GearForm @submit="submit"></GearForm>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GearForm from "@/components/GearForm.vue";
import router from "@/router";
import { url } from "@/consts/consts";
export default defineComponent({
  name: "addNewGear",
  components: { GearForm },
  setup() {
    async function submit(value: any): Promise<void> {
      const response = await fetch(`${url}/api/add-gear`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
        if(response.status === 201){
            await router.push({name: 'gearList'});
        }
    }
    return { submit };
  },
});
</script>
