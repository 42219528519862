<template>
  <div class="home">
    <v-row>
      <v-col cols="12">
          <v-btn to="/magazyn/gearList"> Lista sprzętu </v-btn>
      </v-col>
        <v-col cols="12" v-if="store.isAdmin()">
            <v-btn to="/magazyn/addNewGear"> Dodaj nowy sprzęt </v-btn>
        </v-col>
        <v-col cols="12">
            <v-btn to="/magazyn/newRent"> Wypożycz sprzęt</v-btn>
        </v-col>
        <v-col cols="12">
            <v-btn to="/magazyn/rentList"> Lista wypożyczeń </v-btn>
        </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {store} from "@/store/store";

export default defineComponent({
  name: "HomeView",
    computed: {
        store() {
            return store
        }
    },
  setup() {
    return {};
  },
});
</script>
